<template>
  <!-- 阅读设置Drawer Begin -->
  <a-drawer
    title="阅读设置"
    @close="handleOnClose"
    :visible="this.drawerVisible"
  >
    <a-row class="row">
      <a-col :span="24" class="lable">字体大小</a-col>
    </a-row>
    <a-row class="row">
      <a-col :span="24">
        <a-slider
          :min="16"
          :max="48"
          :marks="marks"
          v-model="fontSizeValue"
          @change="handleOnChange"
        />
      </a-col>
    </a-row>
    <a-row class="row" style="margin:-10px 5px 0px 5px">
      <a-col :span="8">
        <div class="gutter-box">
          <a-icon type="font-size" style="font-size:14px" />
        </div>
      </a-col>
      <a-col :span="8">
        <div class="gutter-box" style="text-align:center">{{this.fontSizeValue}}</div>
      </a-col>
      <a-col :span="8">
        <div class="gutter-box" style="text-align:right">
          <a-icon type="font-size" style="font-size:24px" />
        </div>
      </a-col>
    </a-row>
    <a-divider />
    <a-row class="row">
      <a-col :span="18" class="lable">沉浸式阅读</a-col>
      <a-col :span="6">
        <a-switch :defaultChecked="this.switchChecked" @change="handleOnSwitch" />
      </a-col>
    </a-row>
    <a-divider />
    <a-row class="row">
      <a-col :span="24" class="lable">主题(仅沉浸式阅读模式可用)</a-col>
    </a-row>
    <a-row class="row">
      <a-col :span="24">
        <a-row type="flex">
          <a-col :order="themes.length" :key="i" v-for="(item,i) in themes">
            <div class="gutter-box">
              <a-tooltip :key="i">
                <template slot="title">{{item.description}}</template>
                <a-tag :color="item.bgColor" @click="handleThemeChange(item)">
                  {{item.description}}
                  <span v-if="setTagChecked(item.description)">✓</span>
                </a-tag>
              </a-tooltip>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-divider />
    <!--
    <a-row class="row">
      <a-col :span="24" class="lable"> 字体(仅适用于沉浸式阅读) </a-col>
    </a-row>
    <a-row class="row">
      <a-col :span="24">
        <a-select defaultValue="" style="width: 120px">
          <a-select-option v-for="(item,i) in filterFontFamily()" :value="item.name" >{{item.description}}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-divider />
    -->
    <template slot="footer">
      <a-button key="close" @click="handleOnClose" type="primary">关闭</a-button>
    </template>
  </a-drawer>
  <!-- 阅读设置Drawer End -->
</template>

<script>
import Vue from "vue";
import ContentCommon from "../views/ContentCommon";
Vue.prototype.common = ContentCommon;

export default {
  props: {
    fontSize: {
      type: Number,
      default: 26
    },
    immersive: {
      type: Boolean,
      default: true //immersive｜default
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    name: "immersive";
    return {
      switchChecked: this.immersive,
      fontSizeValue: this.fontSize,
      drawerVisible: this.visible,
      marks: {
        20: "20",
        24: "24",
        28: "28",
        32: "32",
        36: "36",
        40: "40",
        44: "44"
      },
      themes: [
        {
          description: "护眼灰",
          color: "#CECECF",
          bgColor: "#3A393C",
          version: 1
        },
        {
          description: "省电黑",
          color: "#CECECF",
          bgColor: "#000000",
          version: 1
        },
        {
          description: "卡其棕",
          color: "#3D2516",
          bgColor: "#F6EEDC",
          version: 1
        },
        {
          description: "淡雅蓝",
          color: "#151515",
          bgColor: "#94C4DE",
          version: 1
        }
      ],
      fontFamilys: [
        {
          name: "SimSun",
          description: "宋体",
          os: "win"
        },
        {
          name: "SimSun",
          description: "Microsoft Yahei",
          os: "win"
        },
        {
          name: "KaiTi",
          description: "楷体",
          os: "win"
        },
        {
          name: "FangSong",
          description: "仿宋",
          os: "win"
        },
        {
          name: "STFangsong",
          description: "华文仿宋",
          os: "mac"
        },
        {
          name: "PingFang SC",
          description: "苹方",
          os: "mac"
        },
        {
          name: "STSong",
          description: "宋体",
          os: "mac"
        },
        {
          name: "STXihei",
          description: "细黑",
          os: "mac"
        },
        {
          name: "STKaiti",
          description: "楷体",
          os: "mac"
        },
        {
          name: "Yuanti SC",
          description: "圆体",
          os: "mac"
        },
        {
          name: "宋体,Arial Narrow",
          description: "宋体",
          os: "other"
        }
      ]
    };
  },
  components: {},
  computed: {
    storeCustomSettings() {
      var setting = this.$store.state.customSettings;
      return setting;
    }
  },
  watch: {
    visible(val) {
      this.drawerVisible = val;
    }
    /*
      'storeCustomSettings.visible':{
        handler(val) {
        //this.drawerVisible = val;
      },
      //deep:true
    }
    */
  },
  created() {
    //localStorage.removeItem("CustomSettings");
    this.drawerVisible = this.visible;
  },
  beforeUpdate() {},
  beforeMount() {},
  beforeDestroy() {},
  mounted() {},
  methods: {
    saveCustomSettings(data) {
      data.visible = false;
      this.$store.commit("setCustomSettings", data);
      localStorage.setItem("CustomSettings", JSON.stringify(data));
    },
    handleThemeChange(data) {
      let settings = this.$store.state.customSettings;
      settings.theme = data;
      this.common.setTheme(data);

      this.saveCustomSettings(settings);
    },
    handleOnChange(value) {
      let settings = this.$store.state.customSettings;
      settings.fontSize = value;

      this.saveCustomSettings(settings);
    },
    handleOnSwitch(e) {
      let settings = this.$store.state.customSettings;
      settings.immersive = e;
      var lastRoute = this.$route;
      this.saveCustomSettings(settings);

      let path = "/immersive";
      if (!e) {
        path = "/content";
      }
      //替换当前路由
      this.$router.replace({
        path: path,
        query: lastRoute.query,
        params: lastRoute.params
      });
    },
    handleOnClose(e) {
      this.drawerVisible = false;
      //传值给上级组件
      this.$emit("callbackData", false);
    },
    setTagChecked(desc) {
      return desc == this.storeCustomSettings.theme.description;
    },
    filterFontFamily() {
      var os = this.common.getOS();
      return this.fontFamilys.filter((x, index) => {
        return x.os == os.toLocaleLowerCase();
      });
    }
  }
};
</script>
<style scoped>
.row {
  margin: 0px 0px 10px 0px;
}
.row .lable {
  font-weight: bold;
}
.row .center {
  text-align: center;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
}
.gutter-box {
  vertical-align: text-bottom;
  /*background: #00a0e9;*/
  font-size: 16px;
  padding: 3px 0;
}
</style>