import store from "../store";

//content公共类
export default {
  //设置当前浏览记录
  setHistory(url, novel) {
    var files = url.split("/");
    var file = files[files.length - 1];
    var histories = [];

    if (localStorage.getItem("History") == null) {
      histories.concat({ id: novel.id, file: file });
      localStorage.setItem("History", JSON.stringify(histories));
    }

    histories = JSON.parse(localStorage.getItem("History"));
    /*
      换个思路出发，以前的代码思路是：
      1、把存在localStorage中的JSON数据给parse。
      2、根据parse出来的数据，根据指定的筛选条件，筛选出需要的数据，并找出该数据所在数组的索引（后续要根据该索引来修改数组中的数据）。
      3、对筛选出来的数据进行修改。
      4、将筛选出的数据根据索引值，更新至原数组
      5、将数组转为JSON存入localStorage。

      以上思路在jQuery时代没问题，但Vue中，不允许直接使用array[index]=val这种方式来修改，用Vue.set来修改数组又会改变原数组，导致索引值和原索引值不一致。

      新思路就简单粗暴很多了，但非常有效。
      1、把存在localStorage中的JSON数据给parse，这个没什么变化。
      2、根据parse出来的数据，根据指定的筛选条件，筛选出需要的数据，这里唯一的变化就是，把不符合要求的数据给筛选出来。
      3、创建新的对象，然后将新的对象和筛选出来的对象concat在一起。
      4、数组转为JSON存入localStorage。

      虽然只是少了一个步骤，但是代码量减少了很多。
    */

    //把和当前阅读小说不符合的histories数组过滤出来。
    var filterData = histories.filter(item => item.id != novel.id);

    histories = filterData.concat({ id: novel.id, file: file });

    localStorage.setItem("History", JSON.stringify(histories));
  },
  getCustomSettings() {
    let settings = JSON.parse(localStorage.getItem("CustomSettings"));
    if (settings == null || settings.theme == null) {
      settings = store.state.customSettings;
      settings.theme.version = Math.ceil(Math.random() * 100);
    }
    return settings;
  },
  setCustomSettings(settings) {
    store.commit("setCustomSettings", settings);
    localStorage.setItem("CustomSettings", JSON.stringify(settings));
  },
  //设置字体
  setFontSize(size) {
    if (size == null) {
      //从localStorage读取设置
      var settings = this.getCustomSettings();
      size = settings.fontSize;
      this.setCustomSettings(settings);
    }
    //可正常使用，使用CSS变量配合JS来修改字体，优点，简单，缺点，可能存在部分浏览器兼容性问题
    document.body.style.setProperty("--title-size", size + 8 + "px"); //设置CSS变量
    document.body.style.setProperty("--font-size", size + "px"); //设置CSS变量
    //document.body.style.getPropertyValue('--font-size');  //获取CSS变量
  },
  //设置字体
  setTheme(theme) {
    if (theme == null) {
      //从localStorage读取设置
      var settings = this.getCustomSettings();
      theme = settings.theme;
      this.setCustomSettings(settings);
    }
    //可正常使用，使用CSS变量配合JS来修改字体，优点，简单，缺点，可能存在部分浏览器兼容性问题
    document.body.style.setProperty("--font-color", theme.color); //设置CSS变量
    document.body.style.setProperty("--background-color", theme.bgColor); //设置CSS变量
    //document.body.style.getPropertyValue('--font-size');  //获取CSS变量
  },
  getBrowser() {
    let ua = navigator.userAgent.toLocaleLowerCase();
    let browserType = null;
    if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
      browserType = "IE";
    } else if (ua.match(/firefox/) != null) {
      browserType = "firefox";
    } else if (ua.match(/ucbrowser/) != null) {
      browserType = "UC";
    } else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
      browserType = "opera";
    } else if (ua.match(/bidubrowser/) != null) {
      browserType = "baidu";
    } else if (ua.match(/metasr/) != null) {
      browserType = "sougou";
    } else if (
      ua.match(/tencenttraveler/) != null ||
      ua.match(/qqbrowse/) != null
    ) {
      browserType = "QQ";
    } else if (ua.match(/maxthon/) != null) {
      browserType = "maxthon";
    } else if (ua.match(/edg/) != null) {
      browserType = "edge";
    } else if (ua.match(/chrome/) != null) {
      var is360 = this._getMime(
        "type",
        "application/vnd.chromium.remoting-viewer"
      );
      if (is360) {
        browserType = "360";
      } else {
        browserType = "chrome";
      }
    } else if (ua.match(/safari/) != null) {
      browserType = "Safari";
    } else {
      browserType = "others";
    }
    return browserType;
  },
  _getMime(option, value) {
    var mimeTypes = navigator.mimeTypes;
    for (var mt in mimeTypes) {
      if (mimeTypes[mt][option] === value) {
        return true;
      }
    }
    return false;
  },
  getOS() {
    let sUserAgent = navigator.userAgent.toLocaleLowerCase();
    let platform = navigator.platform.toLocaleLowerCase();
    let isWin = platform === "win32" || platform === "windows";
    let isMac =
      platform === "mac68k" ||
      platform === "macppc" ||
      platform === "macintosh" ||
      platform === "macintel";
    if (isMac) return "Mac";
    var isUnix = platform === "x11" && !isWin && !isMac;
    if (isUnix) return "Unix";
    var isLinux = String(platform).indexOf("linux") > -1;
    if (isLinux) return "Linux";
    if (isWin) {
      var isWin2K =
        sUserAgent.indexOf("windows nt 5.0") > -1 ||
        sUserAgent.indexOf("windows 2000") > -1;
      if (isWin2K) return "Win2000";
      var isWinXP =
        sUserAgent.indexOf("windows nt 5.1") > -1 ||
        sUserAgent.indexOf("windows xp") > -1;
      if (isWinXP) return "WinXP";
      var isWin2003 =
        sUserAgent.indexOf("windows nt 5.2") > -1 ||
        sUserAgent.indexOf("windows 2003") > -1;
      if (isWin2003) return "Win2003";
      var isWinVista =
        sUserAgent.indexOf("windows nt 6.0") > -1 ||
        sUserAgent.indexOf("windows vista") > -1;
      if (isWinVista) return "WinVista";
      var isWin7 =
        sUserAgent.indexOf("windows nt 6.1") > -1 ||
        sUserAgent.indexOf("windows 7") > -1;
      if (isWin7) return "Win7";
    }
    if (sUserAgent.indexOf("android") > -1) return "Android";
    if (sUserAgent.indexOf("iphone") > -1) return "iPhone";
    if (sUserAgent.indexOf("symbianos") > -1) return "SymbianOS";
    if (sUserAgent.indexOf("windows phone") > -1) return "Windows Phone";
    if (sUserAgent.indexOf("ipad") > -1) return "iPad";
    if (sUserAgent.indexOf("ipod") > -1) return "iPod";
    return "Unknow OS";
  }
};
